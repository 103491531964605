window.onload = function(){
  var $header = $('header');
  
  var w_h = $(window).height();
  var topBtn = $('.to_top');
  
  if ((navigator.userAgent.indexOf('iPhone') > 0 && navigator.userAgent.indexOf('iPad') == -1) || navigator.userAgent.indexOf('iPod') > 0 || navigator.userAgent.indexOf('Android') > 0) {
    $(window).scroll(function () {
      if ($(this).scrollTop() > 100 && menuOpen == false) {
        topBtn.fadeIn();
      } else {
        topBtn.fadeOut();
      }
    });
  } else {
    $(window).scroll(function () {
      var w_h = $(window).height();
      var s_top = $(this).scrollTop(); //スクロールの値を取得
      var l_top = w_h / 2;
      var ml_top = l_top + s_top / 2;
  
      if ($(this).scrollTop() > 100 && menuOpen == false) {
        topBtn.fadeIn();
      } else {
        topBtn.fadeOut();
      }
      if ($(window).scrollTop() > 300) {
        $header.addClass('fixed');
      } else {
        $header.removeClass('fixed');
      }
    });
  }
  
  $(function(){
    topBtn.hide();
  
    $('a[href^="#"]').click(function(){
      var speed = 500;
      var href= $(this).attr("href");
      var target = $(href == "#" || href == "" ? 'html' : href);
      var position = target.offset().top;
      $("html, body").animate({scrollTop:position}, speed, "swing");
      return false;
    });
  });
  
  // スマホメニュー
  var bnrBtn = $('#g_navi');
  var menuOpen = false;
  var scrollpos;
  
  $('.bg_bl').hide();
  
  var ttt = false;
  
  $(function(){
    $(".menu_btn").on("click", function() {
      if(ttt == false) {
        bnrBtn.fadeIn();
        menuOpen = true;
        $('.om').hide();
        $('.to_top').hide();
        $('.bg_bl').fadeIn();
        scrollpos = $(window).scrollTop();
        $('body').addClass('fixed').css({'top': -scrollpos});
        $(".menu_btn").addClass('opened');
        ttt = true;
      } else {
        bnrBtn.fadeOut();
        menuOpen = false;
        $('.om').show();
        $('.bg_bl').fadeOut();
        $('body').removeClass('fixed').css({'top': 0});
        $(".menu_btn").removeClass('opened');
        window.scrollTo( 0 , scrollpos );
        ttt = false;
      }
    });
  });
  
  
  // アコーディオン
  $(function(){
    $(".ac_menu").on("click", function() {
      $(this).next().slideToggle(); 
      $(this).toggleClass("active"); 
    });
  });
  
  // サイトアクセス時フェードイン 
  $(function(){
    $('body').hide();
    $('body').fadeIn(300);
  });
  
  // $(window).fadeThis();

  // Cookie表示　コメントアウトで非表示（エラーなし）
  // const expire = 365; // 有効期限（日）
  // let cc = document.querySelector('.cookie-consent');
  // let ca = document.querySelector('.cookie-agree');
  // const flag = localStorage.getItem('popupFlag');
  // if (flag != null) {
  //   const data = JSON.parse(flag);
  //   if (data['value'] == 'true') {
  //     popup();
  //   } else {
  //     const current = new Date();
  //     if (current.getTime() > data['expire']) {
  //       setWithExpiry('popupFlag', 'true', expire);
  //       popup();
  //     }      
  //   }
  // } else {
  //   setWithExpiry('popupFlag', 'true', expire);
  //   popup();
  // }
  // ca.addEventListener('click', () => {
  //   cc.classList.add('cc-hide1');
  //   setWithExpiry('popupFlag', 'false', expire);
  // });
  // function setWithExpiry(key, value, expire) {
  //   const current = new Date();
  //   expire = current.getTime() + expire * 24 * 3600 * 1000;
  //   const item = {
  //     value: value,
  //     expire: expire
  //   };
  //   localStorage.setItem(key, JSON.stringify(item));
  // }
  // function popup() {
  //   cc.classList.add('is-show');
  // }
}